import React, { useRef, useState, useEffect, useCallback } from 'react';
import styles from './ProfilePhoto.module.css';
import profilePhoto from '../../Imgs/profilePhoto.png';
import profileZaglushka from '../../Imgs/profileZaglushka.png';
import Imgs from '../../Imgs/Imgs';
import { Oval } from 'react-loader-spinner';
import Avatar from 'react-avatar-edit';
import { checkAccess } from '../../access';
import axios from 'axios';
import { useDB } from 'utils/DB';
import { API } from 'utils/api';
import ImageCropper from 'ImageCropper/ImageCropper';
import {Modal} from "../Modal/Modal"
import { convertToBase64, dataURLToFile } from 'utils/lib/helpers/helpers';
import zIndex from '@mui/material/styles/zIndex';

let spiner = (
  <Oval
    height={80}
    width={80}
    color="#ffff"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel="oval-loading"
    secondaryColor="#FF8A35"
    strokeWidth={4}
    strokeWidthSecondary={4}
  />
);

function ProfilePhoto(props) {
  let specialistId = checkAccess();
  let { specialist, updateDB } = useDB();

  const [isImageCropperModalOpen, setImageCropperModalOpen] = useState(false);
  const [updatedAvatar, setUpdatedAvatar] = useState('');

  let [photo, setPhoto] = useState(false);
  let [status, setStatus] = useState('loaded');

  let filePicker = useRef(null);

  async function handleSubmit(sfile) {
    if (!sfile) {
      console.log('Файл не выбран');
      return;
    }

    try {
      setStatus('loading');

      const base64 = await convertToBase64(sfile); // Конвертируем файл в base64

      let response = await fetch(`${process.env.REACT_APP_API}/upload`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, // JSON вместо FormData
        body: JSON.stringify({ file: base64, specialistId }),
      });

      let jsonResponse = await response.json();

      if (jsonResponse) {
        setPhoto(jsonResponse);
        updatePhoto(jsonResponse);
        setStatus('loaded');
        onClose();
      } else {
        console.log('Помилка завантаження. Спробуйте знову');
      }
      setStatus('loaded');
    } catch (error) {
      setStatus('loaded');
      console.log(error);
    }
  }

  function handleFileChange(e) {
    if (!e.target.files[0]) {
      return;
    }
    handleSubmit(e.target.files[0]);
  }

  async function updatePhoto(imgURL) {
    const response = await API.Specialist.updatePhoto(imgURL);
    if (response) {
      updateDB('specialist');
    }
  }

  useEffect(() => {
    async function af() {
      if (specialist.photo == 0) {
        setPhoto(profilePhoto);
        return;
      }
      setPhoto(specialist.photo);
    }
    af();
  }, []);

  const updateAvatar = useCallback(
    (updatedImage, originalFile) => {
      const file = dataURLToFile(updatedImage, originalFile.name)
      
      setUpdatedAvatar(updatedImage);
      handleSubmit(file)
      onClose()
    },
    [updatedAvatar],
  );

  const onClose = () => {
    setImageCropperModalOpen(false);
  };

  if (props.t == 'test') {
    return (
      <>
        <input
          style={{ display: 'none' }}
          ref={filePicker}
          onChange={handleFileChange}
          type="file"
          name="file"
        />

        <div className={styles.profile_photo_container}>
          <div
            style={{ width: props.width, height: props.width }}
            className={styles.photo}
          >
            {status == 'loading' ? (
              spiner
            ) : (
              <img
                width={props.imgWidth}
                src={photo ? photo : profilePhoto}
                alt="Avatar"
              />
            )}
          </div>
          <div className={styles.edit}>
            <Imgs
              onEdit={() => setImageCropperModalOpen(prev => !prev)}
              img={'edit_photo'}
              edit_icon_size={'25px'}
            />
          </div>
        </div>
        {
          isImageCropperModalOpen && (
              <Modal style={{zIndex: '22'}} onClose={onClose} isOpen={isImageCropperModalOpen}>
                <ImageCropper 
                  avatar={photo ? photo : profilePhoto} 
                  loading={status == 'loading'} 
                  updateAvatar={updateAvatar}
                  onClose={onClose} 
                />
              </Modal>
          )
        }
      </>
    );
  }
}

export default ProfilePhoto;
