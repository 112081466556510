import React, { useEffect, useState } from 'react';
import Imgs from 'Imgs/Imgs';
import styles from './DatePickerBlock.module.css';
import stylesM from './DatePickerBlockM.module.css';

import moment from 'moment';
import { WeekSelector2 } from 'Pages/Calendar/Components/WeekSelector/WeekSelector';
import { useWeekSelectorStore } from 'Pages/Calendar/Components/WeekSelector/WeekSelectorStore';
import { useDatePickerBlockStore } from './DatePickerBlockStore';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useTimePickerBlockStore } from '../TimePickerBlock/TimePickerBlockStore';

//! Блок вибору дати
function DatePickerBlock(props) {
  const { t } = useTranslation();
  let { datesOfWeek } = useWeekSelectorStore();
  let { selectDate, date, getDayType } = useDatePickerBlockStore();
  let { getTimeSlots } = useTimePickerBlockStore();
  let { service } = useChooseServiceStore();

  useEffect(() => {
    if (service && service.length > 0) {
      const findAvailableDate = async () => {
        // Шукаємо перший доступний день для запису.
        for (const day of datesOfWeek) {
          await getTimeSlots({
            services: service,
            date: moment(day).format('YYYY/MM/DD'),
          });

          const { timeSlots } = useTimePickerBlockStore.getState();

          // Якщо знайдемо, то змінюємо вибрану дату на знайдений день.
          if (timeSlots && timeSlots.length > 0) {
            selectDate(moment(day).format('YYYY/MM/DD'));
            break;
          }
        }
      };

      findAvailableDate().catch(console.error);
    }
  }, [service]);

  //! Рендеринг

  if (props.platform == 'mobile') {
    return (
      <>
        <div className={stylesM.container_mobile}>
          <div className={stylesM.line1_mobile}>
            <div className={stylesM.selector}>
              <WeekSelector2 style={{ width: '320px', fontSize: '16px' }} />
            </div>
          </div>
          <div className={stylesM.line2}>
            {datesOfWeek.map((el) => {
              return (
                <Day
                  t={'m'}
                  type={getDayType(el, date)}
                  onClick={() => {
                    selectDate(el);
                  }}
                  id={el}
                  day={getWeekday(el)}
                  number={getDayNumber(el)}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.line1}>
          <div className={styles.title}>{t('when_you_can')}</div>
          <div className={styles.selector}>
            <WeekSelector2 />
          </div>
        </div>
        <div className={styles.line2}>
          {datesOfWeek.map((el) => {
            return (
              <Day
                type={getDayType(el, date)}
                onClick={() => {
                  selectDate(el);
                }}
                id={el}
                day={getWeekday(el)}
                number={getDayNumber(el)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default DatePickerBlock;

//c Допоміжні компоненти

// Вибір неділі
export function WeekSelector(props) {
  let [currentWeeek, setCurrentWeek] = useState();

  //?
  let weekNumber = moment().week() - 2;
  if (props.selectedWeek) {
    weekNumber = props.selectedWeek - 2;
  }
  if (weekNumber == -1) {
    weekNumber = 0;
  }

  let currentYear = new Date().getFullYear();

  let year1 = getWeeks(currentYear, currentYear + 1);

  let year2 = getWeeks(currentYear + 1, currentYear + 2);

  let weeks = year1.concat(year2);

  useEffect(() => {
    setCurrentWeek(weeks[weekNumber]);
    props.getCurrentWeek(weeks[weekNumber]);
  }, [props.selectedWeek]);

  //Вперед
  function next() {
    let i = weeks.indexOf(currentWeeek);
    props.getCurrentWeek(weeks[i + 1]);
    setCurrentWeek(weeks[i + 1]);
  }
  //Назад
  function back() {
    let i = weeks.indexOf(currentWeeek);
    props.getCurrentWeek(weeks[i - 1]);
    setCurrentWeek(weeks[i - 1]);
  }

  //! Рендеринг

  if (props.t == 'm') {
    return (
      <>
        <div className={stylesM.week_selector_container}>
          <div onClick={back} className={stylesM.back}>
            <Imgs width={'25px'} img={'back'} />
          </div>
          <div className={stylesM.week}>{convertDateRange(currentWeeek)}</div>
          <div onClick={next} className={stylesM.next}>
            <Imgs width={'25px'} img={'next'} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.week_selector_container}>
        <div onClick={back} className={styles.back}>
          <Imgs img={'back'} />
        </div>
        <div className={styles.week}>{convertDateRange(currentWeeek)}</div>
        <div onClick={next} className={styles.next}>
          <Imgs img={'next'} />
        </div>
      </div>
    </>
  );
}

export function Day(props) {
  //Mobile
  if (props.t == 'm') {
    if (props.type == 'past') {
      return (
        <div
          id={props.id}
          onClick={props.onClick}
          className={stylesM.day_container_past}
        >
          <div className={stylesM.day_selected}>{props.day}</div>
          <div className={stylesM.day_number_selected}>{props.number}</div>
        </div>
      );
    }

    if (props.type == 'selected') {
      return (
        <div
          id={props.id}
          onClick={props.onClick}
          className={stylesM.day_container_selected}
        >
          <div className={stylesM.day_selected}>{props.day}</div>
          <div className={stylesM.day_number_selected}>{props.number}</div>
        </div>
      );
    }

    if (props.type == 'noTimeslots') {
      return (
        <div
          id={props.id}
          onClick={props.onClick}
          className={stylesM.day_container_past}
        >
          <div className={stylesM.day_selected}>{props.day}</div>
          <div className={stylesM.day_number_selected}>{props.number}</div>
        </div>
      );
    }

    return (
      <div
        id={props.id}
        onClick={props.onClick}
        className={stylesM.day_container}
      >
        <div className={stylesM.day}>{props.day}</div>
        <div className={stylesM.day_number}>{props.number}</div>
      </div>
    );
  }

  //Desktop
  if (props.type == 'past') {
    return (
      <div
        id={props.id}
        onClick={props.onClick}
        className={styles.day_container_past}
      >
        <div className={styles.day_selected}>{props.day}</div>
        <div className={styles.day_number_selected}>{props.number}</div>
      </div>
    );
  }

  if (props.type == 'selected') {
    return (
      <div
        id={props.id}
        onClick={props.onClick}
        className={styles.day_container_selected}
      >
        <div className={styles.day_selected}>{props.day}</div>
        <div className={styles.day_number_selected}>{props.number}</div>
      </div>
    );
  }

  if (props.type == 'noTimeslots') {
    return (
      <div
        id={props.id}
        onClick={props.onClick}
        className={styles.day_container_past}
      >
        <div className={styles.day_selected}>{props.day}</div>
        <div className={styles.day_number_selected}>{props.number}</div>
      </div>
    );
  }

  return (
    <div id={props.id} onClick={props.onClick} className={styles.day_container}>
      <div className={styles.day}>{props.day}</div>
      <div className={styles.day_number}>{props.number}</div>
    </div>
  );
}

//c Допоміжні функції

//Отримати масив неділь
function getWeeks(year1, year2) {
  var weeks = [];
  var date1 = new Date(year1, 0, 1);
  var date2 = new Date(year2, 0, 1);

  // If only a single year is provided, set date2 to the end of that year
  if (!year2) {
    date2 = new Date(year1 + 1, 0, 0);
  }

  // Find the first Monday of the year
  while (date1.getDay() !== 1) {
    date1.setDate(date1.getDate() + 1);
  }

  // Loop through the weeks and add them to the array
  while (date1 < date2) {
    var weekStart = new Date(date1);
    var weekEnd = new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate() + 6,
    );

    weeks.push(
      moment(weekStart).format('YYYY/MM/DD') +
        ' - ' +
        moment(weekEnd).format('YYYY/MM/DD'),
    );
    date1.setDate(date1.getDate() + 7);
  }

  return weeks;
}

//Отримати число з дати
function getDayNumber(dateString) {
  var date = new Date(dateString);
  return date.getDate();
}

//Отримати скорочену назву дня неділі з дати
function getWeekday(date) {
  const weekdays = [
    i18n.t('weekdays.0'),
    i18n.t('weekdays.1'),
    i18n.t('weekdays.2'),
    i18n.t('weekdays.3'),
    i18n.t('weekdays.4'),
    i18n.t('weekdays.5'),
    i18n.t('weekdays.6'),
  ];
  const d = new Date(date);
  return weekdays[d.getDay()];
}

// Форматувати неділю в укр формат
function convertDateRange(dateRange) {
  if (!dateRange) {
    return;
  }

  var dateRangeArr = dateRange.split(' - ');
  var startDateArr = dateRangeArr[0].split('/');
  var endDateArr = dateRangeArr[1].split('/');
  var startDate =
    startDateArr[2] + '/' + startDateArr[1] + '/' + startDateArr[0];
  var endDate = endDateArr[2] + '/' + endDateArr[1] + '/' + endDateArr[0];
  return startDate + ' - ' + endDate;
}
