import React, { useEffect } from 'react';

import styles from './Casa.module.css';
import { useTranslation } from 'react-i18next';
import Header from 'Components/Header/Header';
import settingCasa from '../../Imgs/settingCasa.png';
import CashModals from './Components/CashModals/CashModals';
import {useCashModalsStore} from './Components/CashModals/CashModalsStore';
import TransactionModal from './Components/TransactionModal/TransactionModal';
import {useTransactionModalStore} from './Components/TransactionModal/TransactionModalStore';
import Toast2 from '../../Components/Toast2/Toast2';
import {useCasaStore} from './CasaStore';
import moment from 'moment';
import backImg from '../Clients/ClientsMobile/Images/Vector.svg';
import { useNavigate } from 'react-router-dom';
import {DateRangePicker, CustomProvider} from 'rsuite';
import { usePredefinedRanges } from 'utils/vars';
import {useDateRangePickerLocale} from '../../utils/hooks';
import {useCurrency} from 'hooks/useCurrency';

function Casa() {
  const { t, i18n } = useTranslation(); 
  const navigate = useNavigate()
  const predefinedRanges = usePredefinedRanges()
  const dateRangePickerLocale = useDateRangePickerLocale()
  const {
    transactions, totalAmount, netProfit, 
    transactionCount, getTransactions, activeTab, 
    setActiveTab, calendarHandler, period } = useCasaStore()
  const { handleSettingsWindow } = useCashModalsStore();
  const { handleToggleWindow, handleTransactionForm, editTransaction } = useTransactionModalStore();
  const {currency} = useCurrency()

  const currentLanguage = i18n.language; 

  const onDetails = (transaction) => {
    editTransaction()
    handleTransactionForm({...transaction});
    handleToggleWindow(true);
  };

  useEffect(() => {
    getTransactions(period)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  // //Mobile
  if (window.innerWidth < 1025) {
    return (
      <div className={styles.root}>
      <Toast2 />
      <div className={`${styles.container} ${styles.paginateMenu} `}>
        <img
          onClick={() => {
            navigate(-1);
          }}
          style={{width: '40px'}}
          src={backImg}
          alt="back"
        />
        <button className={styles.addTransaction} onClick={() => handleToggleWindow(true)}>{t('fin_add_transaction')}</button>
      </div>
      <div className={styles.paginate}>
        <CustomProvider locale={dateRangePickerLocale}>
          <DateRangePicker
            format="dd/MM/yyyy"
            placement="auto"
            showOneCalendar={window.innerWidth < 1024 ? true : false}
            locale={dateRangePickerLocale}
            isoWeek="8601"
            placeholder={t('choose_time_slot')}
            ranges={
              window.innerWidth < 1024
                ? predefinedRanges.map((range) => ({
                    ...range,
                    placement: 'bottom',
                  }))
                : predefinedRanges
            }
            value={period}
            onChange={calendarHandler}
          />
        </CustomProvider>
      </div>
    
      <div className={styles.container}>
         <div className={styles.statContainer}>
            <div className={styles.turnover}><span className={styles.subTitle}>
            {t('fin_turnover')}</span> {netProfit} грн</div>
          </div>
        <div className={styles.headerContainer}>
          
          <div className={styles.tabContainer}>
            <span
              className={`${styles.tab} ${activeTab === 'income' ? styles.activeTab : styles.inactiveTab}`}
              onClick={() => setActiveTab('income')}
            >
              {t('fin_income')}
            </span>
            <span
              className={`${styles.tab} ${activeTab === 'expense' ? styles.activeTab : styles.inactiveTab}`}
              onClick={() => setActiveTab('expense')}
            >
              {t('fin_expenses')}
            </span>
          </div>
         
          <button className={styles.settings} onClick={() => handleSettingsWindow(true)}>
            <img src={settingCasa} alt="" />
          </button>
        </div>

        <div className={styles.summaryContainer}>
          <div>
            {t('fin_total')} <span className={styles.totalAmount}>{totalAmount} грн</span> {t('fin_transactions')} <span className={styles.transactionCount}>{transactionCount}</span>
          </div>
          <div className={styles.download}>{t('fin_download_xml')}</div>
        </div>
  
         
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableRow}>
                <th className={styles.tableHeader}>{t('fin_number')}</th>
                <th className={styles.tableHeader}>{t('fin_date')}</th>
                <th className={styles.tableHeader}>{t('fin_amount')}</th>
                <th className={styles.tableHeader}>{t('fin_cash')}</th>
                <th className={styles.tableHeader}>{t('fin_type')}</th>
                <th className={styles.tableHeader}>{t('fin_category')}</th>
                <th className={styles.tableHeader}>{t('fin_client')}</th>
                <th className={styles.tableHeader}></th>
              </tr>
            </thead>
            <tbody>
              {
                transactions.map((transaction) => {
                  const formattedDate = moment(transaction.date)
                  .locale(currentLanguage)
                  .format('D MMMM YYYY р.');

                  return (
                    <tr className={styles.tableRow} key={transaction.transactionNumber}>
                      <td className={styles.tableCell}>{transaction.transactionNumber}</td>
                      <td className={styles.tableCell}>{formattedDate}</td>
                      <td className={styles.tableCell}>{transaction.amount} грн</td>
                      <td className={styles.tableCell}>{transaction.paymentMethod.label}</td>
                      <td className={styles.tableCell}> {
                        transaction.transactionType === 'income' 
                          ? t('fin_income_details')
                          : t('fin_expenses_details')
                        }
                      </td>
                      <td className={styles.tableCell}>{transaction.categoryName.label}</td>
                      <td className={styles.tableCell}>{transaction.clientName.label}</td>
                      <td className={`${styles.tableCell} ${styles.details}`}>
                        <span onClick={() => onDetails(transaction)}>{t('fin_transaction_details')}</span> 
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <CashModals />
      <TransactionModal/>
    </div>
    );
  }

  //Desktop
  return (
    <div className={styles.root}>
      <Toast2 />
      <Header platform={'desktop'} />

      <div className={`${styles.container} ${styles.paginateMenu} `}>
        <span className={styles.name}>{t('fin_cash_register')}</span>
        <div className={styles.paginate}>
          <CustomProvider locale={dateRangePickerLocale}>
            <DateRangePicker
              format="dd/MM/yyyy"
              placement="auto"
              showOneCalendar={window.innerWidth < 1024 ? true : false}
              locale={dateRangePickerLocale}
              isoWeek="8601"
              placeholder={t('choose_time_slot')}
              ranges={
                window.innerWidth < 1024
                  ? predefinedRanges.map((range) => ({
                      ...range,
                      placement: 'bottom',
                    }))
                  : predefinedRanges
              }
              value={period}
              onChange={calendarHandler}
            />
          </CustomProvider>
        </div>
        <button className={styles.addTransaction} onClick={() => handleToggleWindow(true)}>{t('fin_add_transaction')}</button>
      </div>

      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.tabContainer}>
            <span
              className={`${styles.tab} ${activeTab === 'income' ? styles.activeTab : styles.inactiveTab}`}
              onClick={() => setActiveTab('income')}
            >
              {t('fin_income')}
            </span>
            <span
              className={`${styles.tab} ${activeTab === 'expense' ? styles.activeTab : styles.inactiveTab}`}
              onClick={() => setActiveTab('expense')}
            >
              {t('fin_expenses')}
            </span>
          </div>
          <div className={styles.statContainer}>
            <div className={styles.turnover}><span className={styles.subTitle}>
            {t('fin_turnover')}</span>{netProfit} грн</div>
          </div>
          <button className={styles.settings} onClick={() => handleSettingsWindow(true)}>
            <img src={settingCasa} alt="" />
          </button>
        </div>

        <div className={styles.summaryContainer}>
          <div>
            {t('fin_total')} <span className={styles.totalAmount}>{totalAmount} грн</span> {t('fin_transactions')} <span className={styles.transactionCount}>{transactionCount}</span>
          </div>
          <div className={styles.download}>{t('fin_download_xml')}</div>
        </div>
  
         
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableRow}>
                <th className={styles.tableHeader}>{t('fin_number')}</th>
                <th className={styles.tableHeader}>{t('fin_date')}</th>
                <th className={styles.tableHeader}>{t('fin_amount')}</th>
                <th className={styles.tableHeader}>{t('fin_cash')}</th>
                <th className={styles.tableHeader}>{t('fin_type')}</th>
                <th className={styles.tableHeader}>{t('fin_category')}</th>
                <th className={styles.tableHeader}>{t('fin_client')}</th>
                <th className={styles.tableHeader}></th>
              </tr>
            </thead>
            <tbody>
              {
                transactions.map((transaction) => {
                  const formattedDate = moment(transaction.date)
                  .locale(currentLanguage)
                  .format('D MMMM YYYY р.');

                  return (
                    <tr className={styles.tableRow} key={transaction.transactionNumber}>
                      <td className={styles.tableCell}>{transaction.transactionNumber}</td>
                      <td className={styles.tableCell}>{formattedDate}</td>
                      <td className={styles.tableCell}>{transaction.amount} {currency}</td>
                      <td className={styles.tableCell}>{transaction.paymentMethod.label}</td>
                      <td className={styles.tableCell}> {
                        transaction.transactionType === 'income' 
                          ? t('fin_income_details')
                          : t('fin_expenses_details')
                        }
                      </td>
                      <td className={styles.tableCell}>{transaction.categoryName.label}</td>
                      <td className={styles.tableCell}>{transaction.clientName.label}</td>
                      <td className={`${styles.tableCell} ${styles.details}`}>
                        <span onClick={() => onDetails(transaction)}>{t('fin_transaction_details')}</span> 
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
      

        </div>
      </div>
      <CashModals />
      <TransactionModal/>
    </div>
  );
}

export default Casa;
