import { create } from 'zustand';
import axios from 'axios';
import { checkAccess, getSpecialistId } from '../../access';
import { useToast2 } from '../../Components/Toast2/ToastStore';
import { useMobileServicesStore } from 'Mobile/MobileServices/MobileServicesStore';
import { API } from 'utils/api';
import shortId from 'shortid';
import i18n from 'i18n';
import Cookies from 'js-cookie';
let { setPage } = useMobileServicesStore.getState();
let { showToast } = useToast2.getState();

export let useServicesStore = create((set, get) => ({
  service: {
    _id: '',
    category: i18n.t('no_category'),
    name: i18n.t('service_name'),
    price: '',
    hours: 0,
    minutes: 0,
    description: '',
    specialistId: '',
  },
  services: [],

  addServiceWindow: false,
  editServiceWindow: false,

  //! Індивідуальні послуги

  serviceInputHandler: (e) => {
    set({ service: { ...get().service, [e.target.id]: e.target.value } });
  },

  addService: async () => {
    let service = get().service;
    service.specialistId = checkAccess();

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/services`,
      service,
      { withCredentials: true },
    );
    if (data.msg == 1) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('s', i18n.t('service_added'));
      get().getSpecialistServices();
      if (window.innerWidth < 1025) {
        setPage('services');
      }
      if (window.innerWidth > 1025) {
        set({ editServiceWindow: false, zaglushka: true });
      }
    }
  },

  addTestService: async () => {
    let service = {
      _id: '',
      category: i18n.t('test_category'),
      name: i18n.t('test_service_name'),
      price: '400',
      hours: 1,
      minutes: 0,
      description: i18n.t('test_service_description'),
      specialistId: getSpecialistId(),
    };
    service.specialistId = checkAccess();

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/services`,
      service,
      { withCredentials: true },
    );
    if (data.msg == 1) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('s', i18n.t('service_added'));
      get().getSpecialistServices();
      if (window.innerWidth < 1025) {
        setPage('services');
      }
      if (window.innerWidth > 1025) {
        set({
          editServiceWindow: false,
          zaglushka: true,
        });
      }
    }
  },

  updateService: async () => {
    let { data } = await axios.put(
      `${process.env.REACT_APP_API}/api/services/${get().service._id}`,
      get().service,
      { withCredentials: true },
    );
    if (data == 1) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('s', i18n.t('changes_saved'));

      get().getSpecialistServices();

      if (window.innerWidth < 1025) {
        setPage('services');
      }
      if (window.innerWidth > 1025) {
        set({ editServiceWindow: false, zaglushka: true });
      }
    }
  },

  deleteService: async () => {
    let { data } = await axios.delete(
      `${process.env.REACT_APP_API}/api/services/${get().service._id}`,
    );
    if (data == 1) {
      get().getSpecialistServices();
      showToast('s', i18n.t('service_deleted'));

      if (window.innerWidth < 1025) {
        setPage('services');
      }
      set({
        deleteConfirmationWindow: false,
        editServiceWindow: false,
        zaglushka: true,
      });
    }
  },

  isFirstViewServices: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialist_services/${checkAccess()}`,
      { withCredentials: true },
    );
    let isFirstViewServices = Cookies.get('isFirstViewServices');
    if (
      !data.length &&
      (!isFirstViewServices || isFirstViewServices === 'true')
    ) {
      get().addTestService();
    }
    Cookies.set('isFirstViewServices', 'false');
  },

  getSpecialistServices: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialist_services/${checkAccess()}`,
      { withCredentials: true },
    );
    set({ services: data });
  },

  checkTimeSlotConflict: (newSlots, currentServiceId = null) => {
    const { groupServices, groupService } = get();

    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };

    const doTimeRangesOverlap = (start1, end1, start2, end2) => {
      return start1 < end2 && start2 < end1;
    };

    return newSlots.some((newSlot) => {
      const newDuration =
        (groupService.hours || 0) * 60 + (groupService.minutes || 0);
      const newStartMinutes = timeToMinutes(newSlot.start);
      const newEndMinutes = newStartMinutes + newDuration;

      return groupServices.some((service) => {
        if (service._id === currentServiceId) return false;

        const existingDuration =
          (service.hours || 0) * 60 + (service.minutes || 0);

        return service.schedule.some((existingSlot) => {
          if (existingSlot.day !== newSlot.day) return false;
          if (existingSlot.id === newSlot.id) return false;

          const existingStartMinutes = timeToMinutes(existingSlot.start);
          const existingEndMinutes = existingStartMinutes + existingDuration;

          return doTimeRangesOverlap(
            newStartMinutes,
            newEndMinutes,
            existingStartMinutes,
            existingEndMinutes,
          );
        });
      });
    });
  },

  selectService: (serviceId) => {
    if (window.innerWidth < 1025) {
      setPage('edit');
    }
    if (window.innerWidth > 1025) {
      set({ addServiceWindow: false });
    }

    let [data] = get().services.filter((el) => {
      return el._id == serviceId;
    });
    set({ service: data });
    set({ zaglushka: false, editServiceWindow: true });
  },

  showAddServiceWindow: () => {
    set({
      service: {
        _id: '',
        category: i18n.t('no_category'),
        name: i18n.t('service_name'),
        price: '',
        hours: 0,
        minutes: 0,
        description: '',
        specialistId: '',
      },
    });
    if (window.innerWidth < 1025) {
      setPage('addNew');
      return;
    }
    set({ zaglushka: false, addServiceWindow: true });
  },

  //! Групові послуги

  groupService: {
    _id: '',
    name: i18n.t('service_name'),
    price: '',
    hours: 0,
    minutes: 0,
    description: '',
    maxNumberOfParticipants: 0,
    schedule: [],
    specialistId: '',
  },

  groupServices: [],

  groupTimeSlots: [],

  addGroupServiceWindow: false,
  editGroupServiceWindow: false,

  groupServiceInputHandler: (e) => {
    set({
      groupService: { ...get().groupService, [e.target.id]: e.target.value },
    });
  },

  addGroupTimeSlot: () => {
    set({
      groupTimeSlots: [
        ...get().groupTimeSlots,
        { id: shortId.generate(), day: 'Понеділок', start: '08:00' },
      ],
    });
  },

  deleteGroupTimeSlot: (i) => {
    let data = get().groupTimeSlots;
    data.splice(i, 1);
    set({ groupTimeSlots: data });
  },

  groupTimeslotInputHandler: (e, i) => {
    let data = [...get().groupTimeSlots];
    data[i][e.target.id] = e.target.value;
    set({ groupTimeSlots: data });
  },

  addGroupService: async () => {
    const {
      groupService,
      groupTimeSlots,
      checkTimeSlotConflict,
      persistentGroupMembers,
      getSpecialistGroupServices,
    } = get();

    if (groupService.maxNumberOfParticipants == 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('e', i18n.t('specify_max_group_size'));
      return;
    }

    if (groupService.hours <= 0 && groupService.minutes <= 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('e', i18n.t('specify_duration'));
      return;
    }

    if (groupTimeSlots.length == 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('e', i18n.t('add_schedule'));
      return;
    }

    if (checkTimeSlotConflict(groupTimeSlots)) {
      showToast('e', i18n.t('time_slot_conflict'));
      return;
    }

    let newGroupService = groupService;
    newGroupService.specialistId = checkAccess();
    newGroupService.schedule = groupTimeSlots;
    newGroupService.persistentGroupMembers = persistentGroupMembers;

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/group_services`,
      newGroupService,
      { withCredentials: true },
    );
    if (data) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      getSpecialistGroupServices();
      showToast('s', i18n.t('group_service_added'));

      if (window.innerWidth < 1025) {
        setPage('services');
        return;
      }
      set({
        addGroupServiceWindow: false,
        zaglushka: true,
        sectionOfgroupWindow: 'інформація',
        persistentGroupMembers: [],
      });
    }
  },

  updateGroupService: async () => {
    const {
      groupService,
      groupTimeSlots,
      checkTimeSlotConflict,
      persistentGroupMembers,
      getSpecialistGroupServices,
    } = get();

    if (groupService.maxNumberOfParticipants == 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('e', i18n.t('specify_max_group_size'));
      return;
    }

    if (groupService.hours <= 0 && groupService.minutes <= 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('e', i18n.t('specify_duration'));
      return;
    }

    if (groupTimeSlots.length == 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('e', i18n.t('add_schedule'));
      return;
    }

    if (checkTimeSlotConflict(groupTimeSlots)) {
      showToast('e', i18n.t('time_slot_conflict'));
      return;
    }

    let newGroupService = groupService;
    newGroupService.specialistId = checkAccess();
    newGroupService.schedule = groupTimeSlots;
    newGroupService.persistentGroupMembers = persistentGroupMembers;

    let { data } = await axios.put(
      `${process.env.REACT_APP_API}/api/group_services/${groupService._id}`,
      groupService,
      { withCredentials: true },
    );
    if (data) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('s', i18n.t('changes_saved'));
      getSpecialistGroupServices();

      if (window.innerWidth < 1025) {
        setPage('services');
        return;
      }

      set({
        editGroupServiceWindow: false,
        zaglushka: true,
        sectionOfgroupWindow: 'інформація',
        persistentGroupMembers: [],
      });
    }
  },

  getSpecialistGroupServices: async () => {
    let { data } = await axios.get(
      `${
        process.env.REACT_APP_API
      }/api/specialist_group_services/${checkAccess()}`,
      { withCredentials: true },
    );
    set({ groupServices: data });
  },

  deleteGroupService: async () => {
    let { data } = await axios.delete(
      `${process.env.REACT_APP_API}/api/group_services/${
        get().groupService._id
      }`,
    );
    if (data) {
      get().getSpecialistGroupServices();
      showToast('s', i18n.t('service_deleted'));

      if (window.innerWidth < 1025) {
        setPage('services');
        return;
      }
      set({
        deleteConfirmationWindow: { show: false, object: undefined },
        editGroupServiceWindow: false,
        zaglushka: true,
        sectionOfgroupWindow: 'інформація',
        persistentGroupMembers: [],
      });
    }
  },

  selectGroupService: (serviceId) => {
    if (window.innerWidth < 1025) {
      setPage('editGroupService');
    }

    set({ addGroupServiceWindow: false });
    let [data] = get().groupServices.filter((el) => {
      return el._id == serviceId;
    });
    set({
      groupService: data,
      groupTimeSlots: data.schedule,
      persistentGroupMembers: data.persistentGroupMembers,
    });
    set({
      zaglushka: false,
      editGroupServiceWindow: true,
      sectionOfgroupWindow: 'інформація',
    });
  },

  showAddGroupServiceWindow: () => {
    set({
      groupService: {
        _id: '',
        name: i18n.t('service_name'),
        price: '',
        hours: 0,
        minutes: 0,
        description: '',
        maxNumberOfParticipants: 0,
        schedule: [],
        specialistId: '',
      },
      groupTimeSlots: [],
    });

    set({
      zaglushka: false,
      addGroupServiceWindow: true,
      sectionOfgroupWindow: 'інформація',
      persistentGroupMembers: [],
    });

    if (window.innerWidth < 1025) {
      setPage('addGroupService');
      set({ persistentGroupMembers: [] });
    }
  },

  // !Категорії

  categories: [],
  saveCategoriesButton: false,

  getSpecialistCategories: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );

    if (data.serviceCategories) {
      set({ categories: data.serviceCategories });
    }
  },

  updateSpecialistCategories: async () => {
    let { data } = await axios.put(
      `${process.env.REACT_APP_API}/api/specialists_categories`,
      { specialistId: checkAccess(), categories: get().categories },
      { withCredentials: true },
    );
    if (data) {
      showToast('s', i18n.t('categories_updated'));
      set({ saveCategoriesButton: false });
    }
  },

  addCategoryInput: () => {
    set({ categories: [...get().categories, ''] });
  },

  deleteCategoryInput: (i) => {
    let data = [...get().categories];
    data.splice(i, 1);
    set({ categories: data, saveCategoriesButton: true });
  },

  categoryInputHandler: (e, i) => {
    let data = [...get().categories];
    data[i] = e.target.value;
    set({ categories: data });
    set({ saveCategoriesButton: true });
  },

  //! Інше

  deleteConfirmationWindow: { show: false, object: undefined },
  zaglushka: true,
  servicesBlockSection: 'services',
  serviceType: 'Індивідуальні',
  sectionOfgroupWindow: 'інформація',
  clientSearchInputValue: undefined,
  clients: [],
  clientsOptions: [],
  persistentGroupMembers: [],

  setServicesBlockSection: (section) => {
    set({ servicesBlockSection: section });
  },

  showDeleteConfirmationWindow: (object) => {
    set({ deleteConfirmationWindow: { show: true, object } });
  },

  hideDeleteConfirmationWindow: () => {
    set({ deleteConfirmationWindow: { show: false, object: undefined } });
  },

  changeServiceType: (type) => {
    set({
      serviceType: type,
      addServiceWindow: false,
      editServiceWindow: false,
      addGroupServiceWindow: false,
      editGroupServiceWindow: false,
      zaglushka: true,
      service: {
        _id: '',
        category: i18n.t('no_category'),
        name: i18n.t('service_name'),
        price: '',
        hours: 0,
        minutes: 0,
        description: '',
        specialistId: '',
      },
      groupService: {
        _id: '',
        name: i18n.t('service_name'),
        price: '',
        hours: 0,
        minutes: 0,
        description: '',
        maxNumberOfParticipants: 0,
        schedule: [],
        specialistId: '',
      },
      groupTimeSlots: [],
    });
  },

  setSectionOfgroupWindow: (section) => {
    set({ sectionOfgroupWindow: section });
  },

  clientSearchInputHandler: (data) => {
    if (
      get().persistentGroupMembers.length ==
      get().groupService.maxNumberOfParticipants
    ) {
      showToast('e', i18n.t('participant_limit_exceeded'));
      return;
    }

    set({
      clientSearchInputValue: {
        value: undefined,
        label: i18n.t('select_existing_client'),
        color: '#F4F5F7',
      },
    });
    let [newMember] = get().clients.filter((client) => {
      return client._id == data.value;
    });

    set({
      persistentGroupMembers: [...get().persistentGroupMembers, newMember],
    });
  },

  getSpecialistClients: async () => {
    let data = await API.Client.getAll();
    let clientsOptions = data.map((client) => {
      return {
        value: client._id,
        label: `${client.name} ${client.surname}`,
        phone: client.phone,
      };
    });
    set({ clients: data, clientsOptions: clientsOptions });
  },

  deletePersistentGroupMember: (index) => {
    let data = get().persistentGroupMembers;
    data.splice(index, 1);
    set({ persistentGroupMembers: data });
  },
}));
