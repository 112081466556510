import { create } from 'zustand';
import axios from 'axios';
import { useToast2 } from '../../../../Components/Toast2/ToastStore';
import { useBookingStore } from '../../BookingStore';
import { API } from 'utils/api';
import i18n from 'i18n';
let { showToast } = useToast2.getState();

export let useChooseServiceStore = create((set, get) => ({
  specialist: {},

  services: [],
  groupServices: [],
  filteredServices: [],
  service: undefined,

  categories: [],
  category: i18n.t('all_categories'),

  chooseServiceWindow: false,
  windowSection: 'allServices',

  allGroupTimeSlots: [], //all by service

  getSpecialistInfo: async () => {
    let { specialistId } = useBookingStore.getState();

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${specialistId}`,
    );
    set({ specialist: data, categories: data.serviceCategories });
  },

  getSpecialistServices: async () => {
    let { specialistId } = useBookingStore.getState();

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialist_services/${specialistId}`,
    );
    set({ services: data, filteredServices: data });
  },

  getSpecialistGroupServices: async () => {
    let { specialistId } = useBookingStore.getState();

    let groupServices = await API.GroupService.getAllBySpecialistId({
      specialistId,
    });
    if (groupServices.length > 0) {
      set({
        groupServices,
        categories: !get().categories.includes('Групові')
          ? [...get().categories, 'Групові']
          : get().categories,
      });
    }
    set({
      filteredServices: [
        ...get().filteredServices,
        ...get().groupServices,
      ].filter((item, index, self) => {
        return (
          index ===
          self.findIndex((obj) => obj.id === item.id && obj.name === item.name)
        );
      }),
    });
  },

  getAllGroupTimeSlots: async () => {
    const services = get().service;
    let service = services ? services[0] : undefined;
    let specialistId = useBookingStore.getState().specialistId;

    if (!service) {
      return;
    }

    let groupAppointments = await API.GroupAppointment.getTimeSlots({
      option: 'all',
      specialistId,
      groupServiceId: service._id,
    });
    set({ allGroupTimeSlots: groupAppointments });
  },

  changeWindowSection: (section) => {
    set({ windowSection: section });
  },

  changeChooseServiceWindowState: (state) => {
    set({ chooseServiceWindow: state });
  },

  categoryHandler: (e) => {
    if (!e.target.value) {
      e.target.value = e.target.id;
    }

    set({ category: e.target.value });
    if (e.target.value === i18n.t('all_categories')) {
      set({ filteredServices: [...get().services, ...get().groupServices] });
      return;
    }

    if (e.target.value == 'Групові') {
      set({ filteredServices: get().groupServices });
      return;
    }

    let filteredServices = get().services.filter((service) => {
      return service.category == e.target.value;
    });
    set({ filteredServices: filteredServices });
  },

  selectService: (service) => {
    set({ service: service });
  },

  goNext: () => {
    if (!get().service) {
      showToast('e', i18n.t('choose_service'));
      return;
    }
    set({ chooseServiceWindow: false });
  },
}));
