import Cookies from "js-cookie";
import { CurrencyEnum, LanguageEnum } from "utils/enums";
import { useChooseServiceStore } from '../Pages/Booking/Components/ChooseService/ChooseServiceStore';

export const useCurrency = () => {
  let { specialist } = useChooseServiceStore();
  const currencyId = Cookies.get('currencyId')

  const language = navigator.language || navigator.languages[0];
  const langCode = language.split('-')[0];

  const currency = CurrencyEnum[specialist?.settings?.currency] ?? CurrencyEnum[currencyId] ?? CurrencyEnum[langCode] ?? CurrencyEnum[LanguageEnum.En]

  return { currency }
};