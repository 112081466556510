import axios from 'axios';
import React, { useLayoutEffect, useState } from 'react';
import {
  AgreeWithRules,
  Background,
  Below_Input_Text3,
  Button,
  Input,
  InputsTitle,
  Message,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import styles from './SignUp.module.css';
import { useNavigate } from 'react-router-dom';
import Toast from '../../Components/Toast/Toast';
import SuccessSignUpModal from './Components/SuccessSignUpModal/SuccessSignUpModal';
import { useSuccessSignUpModalStore } from './Components/SuccessSignUpModal/SuccessSignUpModalStore';
import { API } from 'utils/api';
import { useTranslation } from 'react-i18next';

function SignUp() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let { changeSuccessSignUpModal } = useSuccessSignUpModalStore();

  let [signUpForm, setSignUpDorm] = useState({
    email: '',
    password: '',
    confirmedPassword: '',
    name: '',
    surname: '',
  });
  let [error, setError] = useState({ type: '', show: false, msg: '' });
  let [checkBox, setCheckBox] = useState(false);
  let [popup, setPopUp] = useState(false);

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

    //Показати message помилки
    function showError(type, msg) {
      if (type == 'e') {
        type = 'error';
      }
      if (type == 's') {
        type = 'success';
      }
      setError({ type, show: true, msg });
      // setTimeout(() => {
      //   setError({ type: '', show: false, msg: '' });
      // }, 2000);
    }

  //Обробник інпута
  function inputHandler(e) {
    if (e.target.id == 'email') {
      e.target.value = e.target.value.toLowerCase();
    }
    setSignUpDorm((ps) => ({ ...ps, [e.target.id]: e.target.value }));
  }

  //Відправити данні на сервер

  async function sendData() {
    if (!checkData()) {
      return;
    }

    let data = await API.Specialist.signUp(signUpForm);

    if (data == 'success') {
      if (window.innerWidth > 1025) {
        changeSuccessSignUpModal(true);
        return;
      }

      if (window.innerWidth < 1025) {
        window.location.href = '/registration_successful';
        return;
      }
      setPopUp('RegistrationSuccessful');
      return;
    }
    if (data == 'Account already exists') {
      showError('e', t('email_already_registered'));
      return;
    }
    if (data != 'success') {
      showError('e', t('check_field_errors'));
    }
  }

  // Перевірка даанних
  function checkData() {
    // Перевірка емейл
    if (!signUpForm.email.match('@') && !signUpForm.email.match('@')) {
      showError('e', t('invalid_email'));
      return false;
    }

    // Перевірка пароля
    if (signUpForm.password.length == 0) {
      showError('e', t('incorrect_password'));
      return false;
    }

    // Чи однакові паролі
    if (signUpForm.password != signUpForm.confirmedPassword) {
      showError('e', t('passwords_do_not_match'));
      return false;
    }

    // Перевірка чекбокса
    if (!checkBox) {
      showError('e', t('accept_terms'));
      return false;
    }

    return true;
  }

  // Чек бокс
  function changeCheckBoxValue() {
    checkBox ? setCheckBox(false) : setCheckBox(true);
  }

  //! Рендеринг

  //Mobile
  if (windowWidth < 1025) {
    return (
      <>
        <div className={styles.mobile_form_container}>
          <div className={styles.logo_container}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
          </div>

          <InputsTitle
            style={{ paddingTop: '15px' }}
            title={t('register')}
          />
          <Input
            id={'email'}
            onChange={inputHandler}
            placeholder={t('email')}
            show_input_description={true}
          />
          <Input
            id={'password'}
            onChange={inputHandler}
            placeholder={t('password')}
            type={'password'}
          />
          <Input
            id={'confirmedPassword'}
            onChange={inputHandler}
            placeholder={t('confirm_password')}
            type={'password'}
          />
          <Input id={'name'} onChange={inputHandler} placeholder={t('first_name')} />
          <Input
            id={'surname'}
            onChange={inputHandler}
            placeholder={t('last_name')}
          />
          <Message show={error.show} type={error.type} message={error.msg} />
          <AgreeWithRules value={checkBox} onClick={changeCheckBoxValue} />
          <Button name={t('sign_up')} onClick={sendData} />
          <Below_Input_Text3
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      </>
    );
  }

  //WEB

  return (
    <>
      <Background>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            <Imgs img={'planguinLogo'} />
            <InputsTitle
              style={{ paddingTop: '50px' }}
              title={t('register')}
            />
            <Input
              id={'email'}
              onChange={inputHandler}
              placeholder={t('email')}
              show_input_description={true}
            />
            <Input
              id={'password'}
              onChange={inputHandler}
              placeholder={t('password')}
              type={'password'}
            />
            <Input
              id={'confirmedPassword'}
              onChange={inputHandler}
              placeholder={t('confirm_password')}
              type={'password'}
            />

            <div className={styles.inputs_container}>
              <Input id={'name'} onChange={inputHandler} placeholder={t('first_name')} />
              <Input
                id={'surname'}
                onChange={inputHandler}
                placeholder={t('last_name')}
              />
            </div>
            <Message show={error.show} type={error.type} message={error.msg} />
            <AgreeWithRules onClick={changeCheckBoxValue} value={checkBox} />
            <Button name={t('sign_up')} onClick={sendData} />
            <Below_Input_Text3
              onClick={() => {
                navigate('/login');
              }}
            />
          </div>
        </div>
      </Background>
      <SuccessSignUpModal />
    </>
  );
}

export default SignUp;
