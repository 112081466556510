import React, { useState, useLayoutEffect } from 'react';
import {
  Background,
  Button,
  Input,
  InputsTitle,
  Message,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import styles from './ResetPassword.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toast from '../../Components/Toast/Toast';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  let [email, setEmail] = useState();
  let [error, setError] = useState({ type: '', show: false, msg: '' });

 //Показати message помилки
 function showError(type, msg) {
  if (type == 'e') {
    type = 'error';
  }
  if (type == 's') {
    type = 'success';
  }
  setError({ type, show: true, msg });
  // setTimeout(() => {
  //   setError({ type: '', show: false, msg: '' });
  // }, 2000);
}
  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  function inputHandler(e) {
    setEmail(e.target.value);
  }

  async function sendData() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialist/reset_password`,
      { email },
      { withCredentials: true },
    );
    if (!data) {
      showError('e', t('email_not_registered'));
      return;
    }
    if (data) {
      window.scroll(0, 0);
      showError('s', t('password_reset_link_sent'));
    }
  }

  if (windowWidth < 1025) {
    return (
      <>
        <div className={styles.mobile_form_container}>
          <div className={styles.logo_container}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
          </div>

          <InputsTitle
            style={{ paddingTop: '50px' }}
            title={t('enter_register_email')}
          />
          <Input value={email} onChange={inputHandler} placeholder={t('email')} />
          <Message show={error.show} type={error.type} message={error.msg} />
          <Button
            onClick={sendData}
            name={t('reset_password')}
            style={{ marginTop: '25px' }}
          />
          <div className={styles.send_again}>
            {t('did_not_receive_email')}{' '}
            <span
              onClick={sendData}
              style={{ color: 'coral', textDecoration: 'underline' }}
            >
              {t('send_again')}
            </span>
          </div>
          <div
            onClick={() => {
              navigate('/login');
            }}
            className={styles.back}
          >
            {t('back')}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Background>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            <Imgs img={'planguinLogo'} />
            <InputsTitle
              style={{ paddingTop: '50px' }}
              title={t('enter_register_email')}
            />
            <Input
              value={email}
              onChange={inputHandler}
              placeholder={t('email')}
            />
            <Message show={error.show} type={error.type} message={error.msg} />
            <Button
              onClick={sendData}
              name={t('reset_password')}
              style={{ marginTop: '25px' }}
            />
            <div className={styles.send_again}>
              {t('did_not_receive_email')}{' '}
              <span
                onClick={sendData}
                style={{ color: 'coral', textDecoration: 'underline' }}
              >
                {t('send_again')}
              </span>
            </div>
            <div
              onClick={() => {
                navigate('/login');
              }}
              className={styles.back}
            >
              {t('back')}
            </div>
          </div>
        </div>
      </Background>
    </>
  );
}

export default ResetPassword;
