import React, { useEffect, useState } from 'react';
import styles from './ProfileBlockView.module.css';
import {
  Button,
  ServiceBlock,
} from 'Components/BasicComponents/BasicComponents';
import call from 'Imgs/Others/call.png';
import ChooseService from '../ChooseService/ChooseService';
import { SocialNetwork } from 'Components/BasicComponents/BasicComponents';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';
import { useProfileBlockViewStore } from './ProfileBlockViewStore';
import { useBookingStore } from 'Pages/Booking/BookingStore';
// import { usePopUpPhoneStore } from '../PopUpPhone/PopUpPhoneStore';
import { useTranslation } from 'react-i18next';
import { useCancelAppointmentStore } from '../CancelAppointment/CancelAppointmentStore';
import CancelAppointment from '../CancelAppointment/CancelAppointment';

function ProfileBlockView(props) {
  const { t } = useTranslation();
  let { getSpecialistInfo, specialist } = useProfileBlockViewStore();
  let { changeChooseServiceWindowState, service } = useChooseServiceStore();
  let { phoneNumberWindowState } = useCancelAppointmentStore();
  let { specialistId } = useBookingStore();
  // let { changePopUpPhoneState } = usePopUpPhoneStore();

  useEffect(() => {
    async function af() {
      await getSpecialistInfo();
    }
    af();
  }, [specialistId]);

  function callPhoneHandler() {
    if (specialist.phone) {
      window.open(`tel:${specialist.phone}`, '_blank');
    }
  }

  return (
    <>
      <ChooseService />
      <CancelAppointment />

      <div className={styles.container}>
        <div className={styles.call_button}>
          <img
            title={!specialist.phone ? t('phone_no_avaliable') : t('phone_avaliable')}
            style={specialist.phone && { cursor: 'pointer' }}
            onClick={callPhoneHandler}
            src={call}
            alt=""
            width={'50px'}
          />
        </div>
        <div className={styles.avatar_container}>
          <div className={styles.inner_container}>
            <img className={styles.avatar} src={specialist?.photo} alt="" />
          </div>
        </div>

        <div className={styles.name}>{specialist?.visibleName} ⭐ </div>
        <div className={styles.about}>{specialist?.about}</div>
        <div className={styles.social_links}>
          {specialist?.instagram != '' && specialist?.instagram ? (
            <SocialNetwork
              show={'view'}
              type={'instagram'}
              value={specialist?.instagram}
              link={`https://instagram.com/${specialist?.instagram}`}
            />
          ) : (
            <></>
          )}
          {specialist?.facebook != '' && specialist?.facebook ? (
            <SocialNetwork
              show={'view'}
              type={'facebook'}
              value={specialist?.facebook}
              link={`https://www.facebook.com/${specialist?.facebook}`}
            />
          ) : (
            <></>
          )}
          {specialist?.youtube != '' && specialist?.youtube ? (
            <SocialNetwork
              show={'view'}
              type={'youtube'}
              value={specialist?.youtube}
              link={`https://www.youtube.com/${specialist?.youtube}`}
            />
          ) : (
            <></>
          )}
          {specialist?.tiktok != '' && specialist?.tiktok ? (
            <SocialNetwork
              show={'view'}
              type={'tiktok'}
              value={specialist?.tiktok}
              link={`https://www.tiktok.com/@${specialist?.tiktok}`}
            />
          ) : (
            <></>
          )}
          {specialist?.whatsapp != '' &&
          specialist?.whatsapp != '+380' &&
          specialist?.whatsapp ? (
            <SocialNetwork
              show={'view'}
              type={'whatsapp'}
              value={specialist?.whatsapp}
              link={`https://wa.me/${specialist?.whatsapp}`}
            />
          ) : (
            <></>
          )}
          {specialist?.telegram != '' &&
          specialist?.telegram != '@' &&
          specialist?.telegram ? (
            <SocialNetwork
              show={'view'}
              type={'telegram'}
              value={specialist?.telegram}
              link={`https://t.me/${specialist?.telegram}`}
            />
          ) : (
            <></>
          )}
          {specialist?.viber != '' &&
          specialist?.viber != '380' &&
          specialist?.viber ? (
            <SocialNetwork
              show={'view'}
              type={'viber'}
              value={specialist?.viber}
              link={`viber://chat/?number=%2B${specialist?.viber}`}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={styles.hint}>
          {`${t('select_service_to_book')} `}
        </div>
        <div className={styles.button_container}>
          {!service?.length ? (
            <div className={styles.button_record}>
              <Button
                name={t('choose_service_1')}
                color={'black'}
                style={{ width: '288px', marginBottom: 15 }}
                onClick={() => {
                  changeChooseServiceWindowState(true);
                }}
              />
              <Button
                name={t('close_service_1')}
                color={'black'}
                style={{ width: '288px' }}
                onClick={() => {
                  phoneNumberWindowState(true);
                }}
              />
            </div>
          ) : (
            <div>
              {service.length ? service.map((item) => {
                return (
                  <ServiceBlock
                    style={{ width: '288px' }}
                    onClick={() => {
                      changeChooseServiceWindowState(true);
                    }}
                    selected={false}
                    name={
                      !item?.schedule ? item?.name : `${item?.name} (${t('group')})`
                    }
                    duration={`${item?.hours} ${t('hours')} ${item?.minutes} ${t('minutes')}`}
                    price={item?.price}
                  />
                )
              })
              : (
                <ServiceBlock
                  style={{ width: '288px' }}
                  onClick={() => {
                    changeChooseServiceWindowState(true);
                  }}
                  selected={false}
                  name={
                    !service?.schedule ? service?.name : `${service?.name} (${t('group')})`
                  }
                  duration={`${service?.hours} ${t('hours')} ${service?.minutes} ${t('minutes')}`}
                  price={service?.price}
                />
              )
            }

              
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileBlockView;
