import React, { useEffect, useState } from 'react';
import styles from './TransactionModal.module.css';
import Imgs from '../../../../Imgs/Imgs';
import { useTranslation } from 'react-i18next';
import { Modal } from 'Components/Modal/Modal';
import { useTransactionModalStore } from './TransactionModalStore';
import {TextArea} from '../../../../Components/BasicComponents/BasicComponents';
import { SelectWidthLabel } from 'Pages/Clients/Components/BasicComponents/BasicComponents';
import {timesOptions} from '../../../../utils/vars';
import {Button, Selector} from 'Components/BasicComponents/BasicComponents';
import {useCurrency} from 'hooks/useCurrency';
import { classNames } from 'utils/lib/classNames/classNames';
import { useCashModalsStore } from '../CashModals/CashModalsStore';
import { useTransactionTypeOptions } from 'utils/hooks';

function TransactionModal(props) {
  const { t } = useTranslation();

  const [isEdit, setEdit] = useState(false);

  const {currency} = useCurrency()
  const typeOptions = useTransactionTypeOptions()

  const {
    isOpen, isPreview, formData, inputHandler,
    handleToggleWindow, saveTransaction, 
    clientsOptions, getSpecialistsClients,
    servicesOptions, getSpecialistServices, 
    handleTransactionForm, cancelTransaction,
    updateTransaction,
  } = useTransactionModalStore();

  const { settings } = useCashModalsStore();

  const categoryOptions = formData.transactionType === 'income' ? settings.categoriesIncome : settings.categoriesExpense;

  useEffect(() => {
    getSpecialistsClients()
    getSpecialistServices()
  }, []);

  useEffect(() => {
    setEdit(false);
  }, [isOpen]);

  useEffect(() => {
    handleTransactionForm({
      paymentMethod: settings?.paymentMethods[0] ,
      categoryName: categoryOptions[0],
      currency
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, servicesOptions, settings, isOpen]);

  return (
      <Modal className={styles.popup_window} onClose={() => handleToggleWindow(false)} isOpen={isOpen}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>
            {isPreview ? t('fin_transaction') : t('fin_new_transaction')} 
            <span className={classNames(styles.transaction_id, {[styles.edit]: isPreview} )}>{formData.transactionNumber}</span> 
          </h3>
          {
            isPreview && !isEdit ? (
              <span 
                onClick={() => setEdit(true)} 
                className={styles.toggle_button} 
                style={{marginBottom: 10}}
              > <Imgs img={'edit'} /> </span>
            ): (
              <span className={styles.toggle_button} onClick={() => handleToggleWindow(false)}> <Imgs img={'close'} /> </span>
            )
          }
        </div>


        <div className={styles.transaction_form}>
          <div className={styles.form_container_row}>
            <div className={styles.form_row}>
              <label className={styles.form_row__label}>{t('date')}</label>
              <input
                value={formData.date}
                id={'date'}
                onChange={inputHandler}
                type={'date'}
                className={styles.input}
                style={{ minWidth: '115px' }}
                readOnly={isPreview && !isEdit}
              />
            </div>
            <div className={styles.form_row}>
              <label className={styles.form_row__label}>{t('time')}</label>
              <Selector
                id={'time'}
                value={formData.time}
                onChange={inputHandler}
                options={timesOptions}
                style={{
                  fontSize: 14,
                  height: 40,
                }}
              />
            </div>
          </div>
          
          <div className={styles.form_row}>
            <label className={styles.form_row__label}>{t('fin_amount')}</label>
            <input 
              id={'amount'} 
              value={formData.amount}
              onChange={inputHandler} 
              type="number" 
              className={styles.input} 
              style={{ width: '97px' }} 
              readOnly={isPreview && !isEdit}
            />

            <span className={styles.currency}>{currency}</span>
          </div>
          
          <div className={styles.form_container_row}>
            <div className={styles.form_row}>
              <label className={styles.form_row__label}>{t('fin_type')}</label>
              <SelectWidthLabel
                className={styles.select_block}
                value={formData.transactionType}
                options={typeOptions}
                id={'transactionType'}
                onChange={inputHandler}
                select={styles.select}
                name="select"
              />
            </div>
            <div className={styles.form_row}>
              <label className={styles.form_row__label}>{t('fin_cash')}</label>
              <SelectWidthLabel
                className={styles.select_block}
                value={formData.paymentMethod?.value}
                options={settings.paymentMethods}
                id={'paymentMethod'}
                onChange={(e) => inputHandler(e, settings.paymentMethods)}
                select={styles.select}
                name="select"
              />
            </div>
          </div>

          <div className={styles.form_row}>
            <label className={styles.form_row__label}>{t('fin_category')}</label>
            <SelectWidthLabel
              className={styles.select_block}
              value={formData.categoryName?.value}
              options={categoryOptions}
              id={'categoryName'}
              onChange={(e) => inputHandler(e, categoryOptions)}
              select={styles.select}
              name="select"
            />
          </div>
          <div className={styles.form_row}>
            <label className={styles.form_row__label}>{t('fin_client')}</label>
            <SelectWidthLabel
              className={styles.select_block}
              value={formData.clientName?.value || clientsOptions.find(({label}) => label === formData.clientName)?.value}
              options={clientsOptions}
              id={'clientName'}
              onChange={(e) => inputHandler(e, clientsOptions)}
              select={styles.select}
              name="select"
            />
          </div>
          <div className={styles.form_row}>
            <label className={styles.form_row__label}>{t('fin_service')}</label>
            <SelectWidthLabel
              className={styles.select_block}
              value={formData.servicesName?.value || servicesOptions.find(({label}) => label === formData.servicesName)?.value}
              options={servicesOptions}
              id={'servicesName'}
              onChange={(e) => inputHandler(e, servicesOptions)}
              select={styles.select}
              name="select"
            />
          </div>

          <div className={styles.form_row}>
            <label className={styles.form_row__label}>{t('comment')}</label>
            <TextArea
              id={'description'}
              value={formData.description}
              onChange={inputHandler}
              style={{ height: '90px', paddingLeft: 15, color: '#6D6D6D', fontSize: '14px', resize: 'none'}}
              readOnly={isPreview && !isEdit}
            />
          </div>
        </div>

        <div className={styles.submit_block}>
          {
            isPreview && !isEdit? (
              <>
                <Button
                  name={t('fin_cancel_transaction')}
                  style={{height: '44px', marginBottom: 10}}
                  color={'black'}
                  onClick={cancelTransaction}
                />
                <Button
                  name={t('back')}
                  style={{
                    color: '#6D6D6D',
                    fontSize: '16px',
                    height: '44px',
                    background: 'none'
                  } }
                  onClick={() => handleToggleWindow(false)}
                />
              </>
            ) : (
              <Button
                name={t('fin_save_transaction')}
                color={'black'}
                onClick={isEdit ? updateTransaction : saveTransaction}
              />
            )
          }
        </div>
        
      </Modal> 
    );
}

export default TransactionModal;
